.character-sheet__allies {
  border: 1px solid var(--dark-gray-0);
  border-top-width: 3px;
  border-bottom-width: 3px;
  width: 100%;
  max-height: 20.7rem;
  height: 100%;

  &--title {
    background-color: var(--light-gray-2);
    padding: 0.4rem;
    border-bottom: 1px solid var(--dark-gray-0);
    text-align: center;
  }

  textarea {
    background: none;
    resize: none;
    border: none;
    width: 100%;
    height: 89.8%;
  }
}
