.character-sheet__perks {
    display: grid;
    grid-template-columns: repeat(11, 12fr);
    grid-template-rows: repeat(6, 1fr);
    border: 2px solid var(--dark-gray-0);
    border-left-width: 1px;
    border-right-width: 1px;
    width: 100%;
  
    &-header {
      border-bottom: 1px solid var(--dark-gold-0);
      grid-area: 1 / 1 / 1 / 12;
      background-color: var(--light-gray-2);
      border-top: 1px solid var(--dark-gray-0);
      
      p {
        font-size: 1.13rem;
        text-align: center;
      }
    }

    &--delete-btn {
      position: absolute;
      right: -1.3rem;
      top: 0rem;
      bottom: 0.1rem;
      max-width: 1.3rem;
      width: 100%;
      border: none;
      box-shadow: none;
      cursor: pointer;
    }

    select {
      border: none;
      padding: 0;
      width: 100%;
      height: 100%;
      background-color: transparent;
    }
  
    @for $i from 1 through 11 {
      &--name-#{$i} {
        grid-area: #{$i} / 1 / #{$i} / 12;
        position: relative;
      }
    }
  }
  