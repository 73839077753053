.character-sheet__heritage-traits {
  border: 1px solid var(--dark-gray-0);
  border-top-width: 3px;
  border-bottom-width: 3px;
  width: 100%;
  height: 22rem;
  
  &--title {
    background-color: var(--light-gray-2);
    padding: 0.4rem;
    border-bottom: 1px solid var(--dark-gray-0);
    text-align: center;
  }
  
  &-container {
    background: none;
    resize: none;
    border: none;
    width: 100%;
    height: 90.5%;
    display: flex;
    flex-direction: column;
    margin: 0.3rem 0.5rem;
    gap: 1rem;

    h2 {
      margin: 0.5rem 0 0.3rem;
    }

    p {
      font-size: var(--font-size-1);
    }
  }
}
