:root {
  --bg-0: var(--dark-gray-0);
  --bg-1: #bcb4b3;
  --bg-2: #e5dfde;
  --light-gray-0: #e4e4e4;
  --light-gray-1: #e9e2e1;
  --light-gray-2: #dcd2d2;
  --dark-gray-0: #938d8c;
  --dark-gray-1: #857f7e;
  --dark-gold-0: #8a8173;
  --dark-gold-1: #b19f70;
  --dark-gold-2: #b98800;
  --silver-0: #928d8c;
  --copper-0: #75400b;
  --purple-0: #372E71;
  --blue-transparent-0: #3d84a0e6;
  --light-blue-0: #c9dfef;
  --dark-red-0: rgb(161, 31, 31);
  --dark-red-1: rgb(199, 62, 62);
  --dark-0: #252323;
  --dark-1: rgb(14,50,84);
  --brown-0: rgb(53,42,29);
  --brown-1: rgb(97,74,52);
  --blue-0: #0b2339;
  --blue-1: rgb(37,95,110);
  --blue-2: rgb(36,119,157);
  --blue-3: rgb(67,200,225);
  --blue-4: #8adcff;
  --blue-5: #00164a;
  --green-0: rgb(29, 173, 36);
  --pink-0: rgb(237, 80, 192);
}
