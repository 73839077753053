.character-sheet__skills {
  display: grid;
  grid-template-columns: repeat(11, 1fr);
  grid-template-rows: repeat(19, 1fr);
  border: 2px solid var(--dark-gray-0);
  border-left-width: 1px;
  border-right-width: 1px;
  width: 100%;
  text-align: center;

  &-header {      
    p {
      font-size: 1.13rem;
    }

    &--name-title {
      grid-area: 1 / 1 / 1 / 7;
      border-top: 1px solid var(--dark-gray-0);
      background-color: var(--light-gray-2);
      border-right: 1px solid var(--dark-gray-0);
    }

    &--roll-title {
      grid-area: 1 / 7 / 1 / 8;
      border-top: 1px solid var(--dark-gray-0);
      background-color: var(--light-gray-2);
    }

    &--bonus-title {
      grid-area: 1 / 8 / 1 / 9;
      border-top: 1px solid var(--dark-gray-0);
      border-right: 1px solid var(--dark-gray-0);
      background-color: var(--light-gray-2);
      border-left: 1px solid var(--dark-gray-0);
    }

    &--total-title {
      grid-area: 1 / 9 / 1 / 12;
      border-top: 1px solid var(--dark-gray-0);
      background-color: var(--light-gray-2);
    }
  }

  @for $i from 2 through 23 {
    &--name-#{$i} {
      grid-area: #{$i} / 1 / #{$i} / 7;
      text-align: start;
      border-right: 1px solid var(--dark-gray-0);
      padding-left: 0.4rem;
    }
    &--roll-#{$i} { grid-area: #{$i} / 7 / #{$i} / 8 }
    &--bonus-#{$i} {
      grid-area: #{$i} / 8 / #{$i} / 9;
      border-right: 1px solid var(--dark-gray-0);
      border-left: 1px solid var(--dark-gray-0);
    }
    &--total-#{$i} { grid-area: #{$i} / 9 / #{$i} / 12 }

    // &--type-#{$i} {
    //   grid-area: #{$i} / 9 / #{$i} / 12;
    //   text-align: center;
    //   color: var(--dark-gold-0);
    // }
  }
}
