.home-bottom-grid {
  background: url('../../../public/images/bottom_home_bg.png') no-repeat center;
  background-size: cover;
  width: 100%;
  min-height: 40rem;
  text-align: center;
}

.home-bottom-grid-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 2rem 0;
  gap: 1rem;
  
  &__box {
    filter: drop-shadow(0 0 0.2rem black);
    position: relative;

    img {
      max-width: 40rem;

      @media (width <= 570px) {
        max-width: 16rem;
      }
    }
  }
}
