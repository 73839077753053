.header {
  text-align: center;
  padding: 5rem 0;
  position: relative;
  background: url('../../../public/images/header_bg.png') no-repeat var(--bg-1);
  background-position: top;
  height: 48rem;
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;

  @media (width <= 570px) {
    padding-top: 0.3rem;
  }

  button {
    background: none;
    box-shadow: none;
    filter: drop-shadow(0 0 0.1rem black);
  }

  &__btn-col {
    display: flex;
    gap: 1.5rem;
    flex-direction: column;
    margin-left: 5rem;
    align-items: baseline;

    @media (width <= 1400px) {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      margin: -1rem 17.5%;
      align-content: center;
    }

    @media (width <= 890px) {
      margin: 0;
    }

    @media (width <= 600px) {
      margin-top: -4.5rem;
    }

    @media (width <= 570px) {
      margin-top: 0;
    }

    &--link {
      max-width: 18rem;
      transition: all 0.15s ease-in-out;

      &:hover {
        transform: scale(1.015, 1.015);
        filter: drop-shadow(0 0 0.2rem var(--blue-3));
      }

      img {
        max-width: 17rem;
      }
    }
  }

  &__featured-content-container {
    position: relative;
    width: fit-content;

    @media (width <= 1400px) {
      margin: auto;

      img {
        max-width: 40rem;
      }
    }

    @media (width <= 700px) {
      margin: auto;

      img {
        max-width: 20rem;
      }
    }
  }

  &__featured-content-border {
    position: absolute;
    top: 1rem;
    max-height: 32rem;

    @media (width <= 1400px) {
      left: 50%;
      transform: translateX(-50%);
    }

    @media (width <= 1347px) {
      top: 1.8rem;
      width: 30rem;
      height: 18.5rem;
    }

    @media (width <= 600px) {
      top: 0;
      width: 27rem;
      max-height: 15rem;
    }
  }

  &__featured-content-box {
    filter: drop-shadow(0 0 0.3rem black);
    max-height: 29rem;
    
    // &--large {
    //   width: 55.7rem;
    //   position: absolute;
    //   top: 2.5rem;
    //   left: 1.8rem;

    //   @media (width <= 1400px) {
    //     left: 50%;
    //     transform: translateX(-50%);
    //   }

    //   @media (width <= 1347px) {
    //     width: 28.2rem;
    //     max-height: 17rem;
    //   }

    //   @media (width <= 600px) {
    //     top: 0.6rem;
    //     width: 25.2rem;
    //     max-height: 14rem;
    //   }
    // }
  }
}
