.cart-page-container {
  background: url('../../../public/images/resources_page_bg.png') no-repeat;
  background-size: cover;
  width: 100%;
  min-height: 53.6rem;
  padding-top: 2rem;
}

.cart-page {
  background-color: var(--light-gray-1);
  padding: 1rem;
  max-width: 30rem;
  margin: auto;
  margin-top: 5rem;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  h1 {
    margin-top: 0;
    font-size: var(--font-size-6);
    text-align: center;
  }

  &__total-cost {
    margin-top: 1rem;
    font-size: var(--font-size-4);
  }

  &__checkout-btn {
    max-width: 5rem;
    margin: 0.5rem auto;
  }

  &__price-container {
    display: flex;
    align-items: baseline;
    gap: 0.3rem;
  }

  &__product {
    display: flex;

    img {
      max-width: 3rem;
      margin-right: 1rem;
      border-radius: 0.3rem;
    }

    h2 {
      margin: 0;
    }

    &--remove-btn {
      margin: auto;
    }

    &--qty {
      display: flex;
      font-weight: 900;
      gap: 0.3rem;
      align-items: center;
      margin-top: 0.3rem;
      
      input {
        max-width: 2.5rem;
        height: fit-content;
      }
    }
  }

  &__back-btn {
    text-align: center;
  }
}
