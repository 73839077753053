.character-sheet__armor {
  display: grid;
  grid-template-columns: repeat(11, 12fr);
  grid-template-rows: repeat(4, 2rem) 0;
  border: 2px solid var(--dark-gray-0);
  border-left-width: 1px;
  border-right-width: 1px;
  width: 100%;

  &-header {
    border-bottom: 1px solid var(--dark-gold-0);
    text-align: center;
    
    p {
      font-size: 1.13rem;
    }

    &--name-title {
      grid-area: 1 / 1 / 1 / 9;
      background-color: var(--light-gray-2);
      border-top: 1px solid var(--dark-gray-0);
    }
    &--lbs-title {
      grid-area: 1 / 9 / 1 / 10;
      border-left: 1px solid var(--dark-gray-0);
      border-right: 1px solid var(--dark-gray-0);
      background-color: var(--light-gray-2);
      border-top: 1px solid var(--dark-gray-0);
    }
    &--def-title {
      grid-area: 1 / 10 / 1 / 12;
      background-color: var(--light-gray-2);
      border-top: 1px solid var(--dark-gray-0);
    }
  }

  &--delete-btn {
    position: absolute;
    right: -1.5rem;
    top: 0.2rem;
    max-width: 1.6rem;
    width: 100%;
    background-color: transparent;
    border: none;
    box-shadow: none;
    cursor: pointer;
  }

  @for $i from 2 through 4 {
    &--name-#{$i} { grid-area: #{$i} / 1 / #{$i} / 9 }
    &--lbs-#{$i} {
      grid-area: #{$i} / 9 / #{$i} / 10;
      border-left: 1px solid var(--dark-gray-0);
      border-right: 1px solid var(--dark-gray-0);
      text-align: center;
    }
    &--def-#{$i} { grid-area: #{$i} / 10 / #{$i} / 12; position: relative; }
  }
}
