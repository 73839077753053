.dropdown {
  position: relative;
  display: inline-block;
  user-select: none;
  -webkit-user-select: none;
  cursor: pointer;
  padding-left: 0;
  min-width: 10rem;

  .selected {
    display: flex;
    align-items: center;
    padding: 0 0.5rem;
    height: 100%;

    img {
      position: absolute;
      max-width: 0.7rem;
      right: 0.3rem;
    }

    &--open {
      padding-left: 0.5rem;

      img {
        position: absolute;
        max-width: 0.7rem;
        right: 0.3rem;
      }
    }
  }

  &__options {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    overflow-x: visible;
    overflow-y: auto;
    padding: 0;
    margin: 0;
    list-style: none;
    border-top: none;
    border-bottom-left-radius: 0.4rem;
    border-bottom-right-radius: 0.4rem;
    background-color: #444343;
    color: white;
    max-height: 20rem;
    z-index: 1;

    &::-webkit-scrollbar {
      width: 0.5rem;
    }

    &::-webkit-scrollbar-track {
      background-color: #444343;
    }

    &::-webkit-scrollbar-thumb {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #2e2b2b;
    }

    li {
      display: flex;
      align-items: center;
      padding: 0.5rem;
      cursor: pointer;
      border: 0.1rem solid #272727;
      border-bottom-left-radius: 0.4rem;
      border-bottom-right-radius: 0.4rem;

      &:hover {
        background-color: #323232;
      }

      &.has-sub-options {
        position: relative;
      }
    }
  }

  &__hover-content {
    position: absolute;
    top: 0;
    right: 100%;
    background-color: #444343;
    color: white;
    padding: 1rem;
    margin-right: 0.3rem;
    border-radius: 0.5rem;
    box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
    width: 16rem;
    z-index: 1;

    h3 {
      text-decoration: none;
    }

    @media (width <= 600px) {
      top: -11rem;
      right: 0;
    }
  }

  &__sub-options {
    position: absolute;
    top: 100%;
    left: 100%;
    right: auto;
    width: 100%;
    overflow-x: visible;
    overflow-y: auto;
    padding: 0;
    margin: 0;
    list-style: none;
    border-top: none;
    border-bottom-left-radius: 0.4rem;
    border-bottom-right-radius: 0.4rem;
    background-color: #444343;
    color: white;
    max-height: 15rem;
    z-index: 1;

    &::-webkit-scrollbar {
      width: 0.5rem;
    }

    &::-webkit-scrollbar-track {
      background-color: #444343;
    }

    &::-webkit-scrollbar-thumb {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #2e2b2b;
    }

    li {
      display: flex;
      align-items: center;
      padding: 0.5rem;
      cursor: pointer;
      border: 0.1rem solid #272727;
      border-bottom-left-radius: 0.4rem;
      border-bottom-right-radius: 0.4rem;

      &:hover {
        background-color: #323232;
      }
    }
  }
}
