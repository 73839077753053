.character-sheet__health {
  display: grid;
  grid-template-columns: repeat(11, 1fr);
  grid-template-rows: 1fr 1fr 1fr;
  border: 2px solid var(--dark-gray-0);
  border-left-width: 1px;
  border-right-width: 1px;
  background: transparent;
  margin-top: 4.5rem;
  text-align: center;
  
  &--exhaustion-title {
    grid-area: 1 / 1 / 1 / 6;
    background-color: var(--light-gray-2);
    border-top: 1px solid var(--dark-gray-0) !important;
    border-right: 1px solid var(--dark-gray-0);
    max-height: 10rem;

    p {
      font-size: var(--font-size-1);
    }
  }

  &--exhaustion-value {
    grid-area: 2 / 1 / 4 / 6;
    max-height: 10rem;
    border-right: 1px solid var(--dark-gray-0);
    
    p {
      font-size: var(--font-size-7);
      height: 100%;
    }
  }

  &--temp-title {
    grid-area: 1 / 6 / 1 / 12;
    background-color: var(--light-gray-2);
    border-top: 1px solid var(--dark-gray-0) !important;
    max-height: 10rem;

    p {
      font-size: var(--font-size-1);
    }
  }

  &--temp-value {
    grid-area: 2 / 6 / 4 / 12;
    max-height: 10rem;

    p {
      font-size: var(--font-size-2);
    }
  }
}
