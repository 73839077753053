.account-page {
  padding: 7rem 5rem;
  background: url('../../../public/images/login_page_bg.png') no-repeat;
  background-size: cover;

  &__info-panel {
    margin: 5rem auto 0;
    background-color: #444343;
    max-width: 80rem;
    height: 25rem;
    padding: 1rem;
    border-radius: 0.3rem;
    color: white;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
  }
}

.account-page-top {
  display: flex;

  &__profile-info {
    margin-right: 2rem;
    text-align: center;

    &--pic-container {
      display: flex;
      flex-direction: column;
      position: relative;
      transition: all 0.15s ease-in-out;

      &:hover {
        transform: scale(1.06, 1.06);
      }
    }

    &--frame {
      max-width: 15rem;
      filter: drop-shadow(0 0 0.3rem black);
      pointer-events:none;
    }

    &--profile-pic {
      position: absolute;
      box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
      cursor: pointer;
      display: block;
      object-fit: cover;
      width: 100%;
      height: auto;
      clip-path: polygon(2% 6%, 14% 6%, 16% 9%, 38% 9%, 43% 3%, 53% 3%, 58% 9%, 79% 9%, 84% 6%, 96% 6%, 95% 17%, 90% 23%, 91% 77%, 96% 81%, 96% 92%, 88% 92%, 89% 98%, 8% 98%, 11% 93%, 1% 92%, 0% 81%, 5% 77%, 8% 21%, 3% 16%);
    }

    &--username {
      position: absolute;
      right: 0;
      left: 0;
      bottom: 0.5rem;
      font-size: var(--font-size-3);
      text-shadow: 0.3px 0.3px 0.5px black;
      color: white;
    }

    &--sign-out {
      margin-top: 1rem;
    }
  }

  &__buttons {
    display: flex;
    gap: 3rem;
    max-width: 55rem;
    flex-wrap: wrap;
    margin: auto;

    button, &--link {
      background: transparent;
      box-shadow: none;
      max-width: 13rem;
      max-height: 3rem;
      padding: 0;
      filter: drop-shadow(0 0 0.3rem black);

      img {
        width: 100%;
      }
    }
  }
}

.profile-pic-selection {
  position: absolute;
  padding: 0.5rem;
  background-color: var(--dark-gray-0);
  max-width: 52rem;
  height: 33rem;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  border-radius: 0.3rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0.5rem;
  }

  &::-webkit-scrollbar-track {
    background-color: #444343;
  }

  &::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #2e2b2b;
  }

  &__close-btn {
    position: absolute;
    right: 0.3rem;
    top: 0.3rem;
    background-color: transparent;
    box-shadow: none;
    font-size: var(--font-size-2);

    &:hover {
      transform: none;
      color: var(--dark-red-0);
    }
  }
  
  &__pics-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 0.8rem;
    gap: 0.5rem;
  }

  &__upload-btn {
    box-shadow: rgba(0, 0, 0, 0.16) 0 1px 4px !important;
    background-color: lightgray !important;
    width: 6rem;
    max-height: 6rem;
    margin-left: 0.3rem;
    margin-top: 0.3rem;
  }

  button {
    background-color: transparent;
    box-shadow: none;
  }

  img {
    max-width: 6rem;
    box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
  }
}



.account-page-purchases {
  .purchases-page__product {
    background-color: #6a6a6a;
    padding: 0.3rem;

    &:nth-child(even) {
      background-color: #5e5e5e;
    }
  }
}

.account-page-characters {
  &__character {
    background-color: #6a6a6a;
    padding: 1rem;

    a {
      text-decoration: none;
    }

    &--name {
      font-size: var(--font-size-2);
      margin-bottom: 0.5rem;
      font-weight: bold;
      color: var(--blue-3);
    }

    &--class {
      font-size: var(--font-size-1);
    }

    &:nth-child(even) {
      background-color: #5e5e5e;
    }
  }
}
