/* stylelint-disable */
@import "./settings/colors";
@import "./settings/font-sizes";
@import "./components/header";
@import "./components/navbar";
@import "./components/characters-page";
@import "./components/modals/modal";
@import "./components/modals/level-up-modal";
@import "./components/character_sheet/character-sheet-page";
@import "./components/dropdown";
@import "./components/home-bottom-grid";
@import "./components/character_sheet/sheet-top-block";
@import "./components/character_sheet/sheet-ability-scores-block";
@import "./components/character_sheet/sheet-gear-block";
@import "./components/character_sheet/sheet-skills-block";
@import "./components/character_sheet/sheet-perks-block";
@import "./components/character_sheet/sheet-blessings-block";
@import "./components/character_sheet/sheet-divine-relics-block";
@import "./components/character_sheet/sheet-weapons-block";
@import "./components/character_sheet/sheet-health-block";
@import "./components/character_sheet/sheet-features-block";
@import "./components/character_sheet/sheet-armor-block";
@import "./components/character_sheet/sheet-companion-block";
@import "./components/character_sheet/sheet-character-details";
@import "./components/character_sheet/sheet-backstory-block";
@import "./components/character_sheet/sheet-heritage-traits-block";
@import "./components/character_sheet/sheet-allies-block";
@import "./components/character_sheet/sheet-special-ammo";
@import "./components/login-register-page";
@import "./components/admin_page/admin-page";
@import "./components/admin_page/admin-features";
@import "./components/admin_page/admin-blessings";
@import "./components/admin_page/admin-perks";
@import "./components/admin_page/admin-culture-traits";
@import "./components/admin_page/admin-languages";
@import "./components/admin_page/admin-gear";
@import "./components/admin_page/admin-weapons";
@import "./components/admin_page/admin-armor";
@import "./components/admin_page/admin-special-ammo";
@import "./components/admin_page/admin-featured-box";
@import "./components/resources-page";
@import "./components/cart-page";
@import "./components/purchases-page";
@import "./components/account-page";
@import "./components/footer";
@import "./components/carousel";
@import "./components/handbook";
/* stylelint-enable */

html,
body {
  background-color: var(--bg-1);
  font-family: Forum, 'Segoe UI', Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  padding: 0;
  margin: 0 auto;
  max-width: 1910px;
  overflow-x: hidden;
}

h1 {
  font-family: 'Castoro Titling', 'Segoe UI', Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

p {
  font-size: var(--font-size-0);
  margin: 0;
}

button {
  cursor: pointer;
  transition: all 0.15s ease-in-out;
  border-radius: 0.3rem;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.16) 0 1px 4px;
  padding: 0.3rem;

  &:hover {
    transform: scale(1.06, 1.06);
  }
}

* {
  box-sizing: border-box;
}

.hidden { display: none; }

textarea::placeholder, textarea {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 0.85rem;
  resize: none;
}

.flex {
  display: flex;
}
