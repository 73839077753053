.character-sheet__weapons {
    display: grid;
    grid-template-columns: repeat(11, 12fr);
    grid-template-rows: repeat(7, 2rem) 0;
    border: 2px solid var(--dark-gray-0);
    border-left-width: 1px;
    border-right-width: 1px;
    position: relative;
    margin-bottom: 1.8rem;
  
    &-header {      
      p {
        font-size: 1.13rem;
        text-align: center;
      }
  
      &--name-title {
        grid-area: 1 / 1 / 1 / 5;
        border-top: 1px solid var(--dark-gray-0);
        background-color: var(--light-gray-2);
      }
      &--range-title {
        grid-area: 1 / 5 / 1 / 6;
        border-top: 1px solid var(--dark-gray-0);
        border-left: 1px solid var(--dark-gray-0);
        border-right: 1px solid var(--dark-gray-0);
        background-color: var(--light-gray-2);
        min-width: 5rem;
      }
      &--exhaustion-title {
        grid-area: 1 / 6 / 1 / 11;
        border-top: 1px solid var(--dark-gray-0);
        border-right: 1px solid var(--dark-gray-0);
        background-color: var(--light-gray-2);
      }
      &--lbs-title {
        grid-area: 1 / 11 / 1 / 12;
        border-top: 1px solid var(--dark-gray-0);
        background-color: var(--light-gray-2);
      }
    }

    &--mod {
      position: absolute;
      bottom: -2rem;
      right: -0.1rem;
      border: 2px solid var(--dark-gray-0);
      border-left-width: 1px;
      border-right-width: 1px;
      display: flex;
      align-items: center;

      &-border {
        height: 2rem;
        border-right: 1px solid var(--dark-gray-0);
      }
      
      p {
        height: 100%;
      }

      span {
        margin-right: 0.7rem;
      }
    }
  
    &--delete-btn {
      position: absolute;
      right: -1.5rem;
      top: 0.15rem;
      max-width: 1.6rem;
      width: 100%;
      background-color: transparent;
      border: none;
      box-shadow: none;
      cursor: pointer;
    }

    &--special-ammo-btn {
      margin-right: 0.3rem;
      height: 1.8rem;
      align-self: center;

      img {
        max-width: 1.3rem;
      }
    }

    &--special-ammo-selection {
      position: absolute;
      background-color: #444343;
      min-width: 10rem;
      color: white;
      max-height: 20rem;
      top: 0;
      left: 0;
      width: 100%;
      overflow-x: visible;
      overflow-y: auto;
      padding: 0;
      margin: 0;
      padding: 0.81rem;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      border-bottom-left-radius: 0.4rem;
      border-bottom-right-radius: 0.4rem;
      z-index: 1;
      display: flex;
      flex-direction: column;
      gap: 0.3rem;

      h4 {
        margin: 0 0 0.81rem;
        font-size: var(--font-size-1);
      }
    }
  
    @for $i from 2 through 7 {
      &--name-#{$i} {
        grid-area: #{$i} / 1 / #{$i} / 5;
        display: flex;
        position: relative;

        span {
          font-size: 0.9rem;
          color: var(--pink-0);
          font-style: italic;
        }
      }
      &--range-#{$i} {
        grid-area: #{$i} / 5 / #{$i} / 6;
        border-left: 1px solid var(--dark-gray-0);
        border-right: 1px solid var(--dark-gray-0);
      }
      &--exhaustion-#{$i} {
        grid-area: #{$i} / 6 / #{$i} / 11;
        border-right: 1px solid var(--dark-gray-0);

        p {
          font-size: 0.88rem;
        }
      }
      &--lbs-#{$i} {
        grid-area: #{$i} / 11 / #{$i} / 12;
        text-align: center;
        position: relative;
      }
    }
  }
  