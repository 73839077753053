.character-sheet__character-details {
  width: 100%;

  table {
    width: 100%;
    border-top-width: 3px;
    border-bottom-width: 3px;
  }

  input, &--dropdown {
    padding: 0 0 0 0.3rem;
    border: solid 1px black;
    margin-left: 0.2rem;
    border-radius: 0.3rem;
  }
}
