.carousel {
  position: relative;
  width: 100%;

  button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: var(--dark-0);
    background-color: white;
    padding: 0.4rem 0.7rem;
    font-size: var(--font-size-2);
    border-radius: 50%;
  }

  &__left-btn {
    left: -0.5rem;
    z-index: 1;

    &:hover {
      transform: translateY(-50%) scale(1.09, 1.09);
    }
  }

  &__right-btn {
    right: -0.5rem;

    &:hover {
      transform: translateY(-50%) scale(1.09, 1.09);
    }
  }

  &__content {
    display: flex;
  }

  &__slide {
    width: 100%;
    box-sizing: border-box;
    padding: 0 10px;
    animation: 'fade-in' 0.3s ease-in-out;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 100;
  }
}
