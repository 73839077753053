.modal-overlay {
  background-color: rgba(0, 0, 0, 0.301);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  z-index: 1;
}

.modal, .new-character-modal {
  position: fixed;
  inset: 0;
  width: fit-content;
  height: fit-content;
  padding: 5rem;
  margin: auto;
  text-align: center;
  border-radius: 0.3rem;
  padding-top: 2rem;
  z-index: 1;

  &--selection {
    max-width: 48rem;
    background: url('../../../../public/images/boxes/feature_box_1.png') no-repeat;
    background-size: cover;
  }

  &__delete-btn {
    position: absolute;
    left: 0.3rem;
    top: 0.3rem;
    background-color: transparent;
    border: none;
    font-size: var(--font-size-3);
    cursor: pointer;
    transition: all 0.1s ease-in-out;

    &:hover {
      color: var(--dark-red-0);
    }
  }
}

.new-character-modal {
  padding: 0;
  background-color: transparent;
  box-shadow: none;
}

.default-modal {
  background: url('../../../../public/images/boxes/feature_box.png') no-repeat center;
  background-size: contain;
  padding: 5rem;
  filter: drop-shadow(0 0 0.5rem black);
}

.dropdown-modal {
  background: url('../../../../public/images/boxes/feature_box_1.png') no-repeat center;
  background-size: contain;
  padding: 3.5rem 14rem 14rem;
  filter: drop-shadow(0 0 0.5rem black);

  h2 {
    white-space: nowrap;
  }

  &--bottom {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 3rem;

    button {
      background-color: transparent;
      box-shadow: none !important;

      img {
        max-width: 12rem;
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
        user-drag: none;
      }
    }
  }
}

.culture-triats-modal, .ability-scores-modal, .starting-gear-modal {
  background: url('../../../../public/images/boxes/feature_box_3.png') no-repeat center;
  background-size: contain;
  padding: 3.5rem 14rem 20rem;
  filter: drop-shadow(0 0 0.5rem black);

  h2 {
    white-space: nowrap;
  }

  &--bottom {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 11.5rem;

    button {
      background-color: transparent;
      box-shadow: none !important;

      img {
        max-width: 12rem;
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
        user-drag: none;
      }
    }
  }
}

.ability-scores-modal {
  padding: 3.5rem 14rem 14rem;
  height: 47rem;

  h2 {
    margin-top: 1rem;
    margin-bottom: 0;
  }

  &--bottom {
    bottom: 12rem;
  }
}

.starting-gear-modal {
  h2 {
    margin-top: 1rem;
  }

  &--bottom {
    bottom: 9rem;
  }  
}

.feature-select-modal {
  background: url('../../../../public/images/boxes/feature_box_4.png') no-repeat center;
  background-size: contain;
  padding: 8rem;
  height: 61rem;
  filter: drop-shadow(0 0 0.5rem black);
  min-width: 58rem;

  h1 {
    margin-top: 1rem;
    margin-bottom: 0;
  }

  p {
    max-width: 42rem;
  }

  &--bottom {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 15rem;

    button {
      background-color: transparent;
      box-shadow: none !important;

      img {
        max-width: 7.5rem;
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
        user-drag: none;
      }
    }
  }

  &--feature {
    margin: auto;

    h2 {
      margin-bottom: -2.2rem;
      margin-top: 0.3rem;
      font-size: var(--font-size-2) !important;
    }

    p {
      font-size: 1rem !important;
    }
  }
}
