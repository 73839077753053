.character-sheet__top-block {
  display: grid;
  grid-template-columns: repeat(11, 1fr);
  grid-template-rows: 1fr 1fr;
  border: 2px solid var(--dark-gray-0);
  border-left-width: 1px;
  border-right-width: 1px;
  margin-top: 4.5rem;

  &--name {
    grid-area: 1 / 1 / 1 / 8;
    border-top: 1px solid var(--dark-gray-0) !important;
    font-size: var(--font-size-0);
  }

  &--rest-dice {
    grid-area: 1 / 8 / 1 / 12;
    border-top: 1px solid var(--dark-gray-0);

    span {
      color: var(--dark-red-0);
    }
  }
  &--rest-dice-value { grid-area: 1 / 9 / 1 / 12 }
 
  &--class {
    grid-area: 2 / 1 / 2 / 8;
    padding-top: 0.14rem;
  }

  &--breathers-taken {
    grid-area: 2 / 8 / 2 / 12;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-top: 0;
  }

  &--level {
    grid-area: 3 / 1 / 3 / 5;
    padding-top: 0;
  }

  &--speed {
    grid-area: 3 / 5 / 3 / 8;
    padding-top: 0;
  }

  &--faith-points {
    grid-area: 3 / 8 / 3 / 12;
    padding-top: 0;
  }

  .breathers-taken-circle {
    border: 1px solid var(--dark-gold-2);
    width: 1rem;
    border-radius: 50%;
    height: 1rem;
    margin: 0 0.3rem;
  }
}