.characters-page {
  background: url('../../../public/images/top_characters_page_bg.png') no-repeat center;
  background-size: cover;
  text-align: center;
  padding: 5rem 0 0;
  position: relative;
  background-position: top;
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  justify-content: center;
  height: 100%;

  @media (width <= 570px) {
    padding-top: 0.3rem;
  }

  &__left-col {
    display: flex;
    gap: 1.5rem;
    flex-direction: column;
    margin-left: 5rem;
    justify-content: center;

    @media (width <= 1400px) {
      margin-left: 0;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  &__featured-content-box {
    position: relative;

    img {
      filter: drop-shadow(0 0 0.3rem black);
      max-height: 32rem;
  
      @media (width <= 1400px) {
        max-height: 30rem;
        width: 100%;
      }
    }
  }
}

.characters-container {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  height: 29.5rem;
  padding: 2rem 1rem;
  max-height: 26rem;
  filter: drop-shadow(0 0 0.3rem black);
  overflow-y: auto;
  justify-content: center;
  clip-path: polygon(97% 84%, 97% 17%, 93% 12%, 92% 6%, 9% 7%, 7% 12%, 5% 15%, 3% 17%, 4% 84%, 5% 87%, 7% 90%, 8% 95%, 92% 95%, 92% 91%, 94% 87%);

  &::-webkit-scrollbar {
    width: 0.5rem;
  }

  &::-webkit-scrollbar-track {
    background-color: #444343;
  }

  &::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #2e2b2b;
  }

  @media (width <= 900px) {
    max-height: 20rem;
    gap: 1rem;
  }

  @media (width <= 760px) {
    max-height: 11rem;
  }

  @media (width <= 466px) {
    margin-top: 0;
    max-height: 8rem;
    gap: 1rem;
  }
}

.new-character-btn {
  background: none;
  box-shadow: none;
  filter: drop-shadow(0 0 0.1rem black);
  max-width: 18rem;
  transition: all 0.15s ease-in-out;

  &:hover {
    transform: scale(1.015, 1.015);
    filter: drop-shadow(0 0 0.2rem var(--blue-3));
  }

  img {
    max-width: 17rem;
  }
}


.character-card-container {
  background: url('../../../public/images/character_card.png') no-repeat center;
  background-size: contain;
  max-width: 13rem;
  display: flex;
  position: relative;
  transition: all 0.2s ease-in-out;
  height: 10rem;
  align-items: center;
  cursor: pointer;

  &--blank {
    background: url('../../../public/images/create_box.png') no-repeat center;
    background-size: contain;
    display: flex;
    position: relative;
    transition: all 0.2s ease-in-out;
    height: 10rem;
    width: 13rem;
    align-items: center;
    cursor: pointer;
    box-shadow: none;
    margin: 0.5rem 0;
  }

  &--locked {
    background: url('../../../public/images/unlock_box.png') no-repeat center;
    background-size: contain;
    display: flex;
    position: relative;
    transition: all 0.2s ease-in-out;
    height: 10rem;
    width: 13rem;
    align-items: center;
    cursor: pointer;
    box-shadow: none;
    margin: 0.5rem 0;
  }

  &:hover, &--blank:hover, &--locked:hover  {
    transform: scale(1.02, 1.02);
  }
}

.character-card {
  text-align: center;
  padding-bottom: 0.5rem;
  max-width: 13rem;
  min-width: 13rem;
  width: 100%;
  position: relative;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  height: 100%;

  img {
    max-width: 5rem;
    border-radius: 50%;
  }

  &__name {
    margin-top: 15%;
    font-size: var(--font-size-4);
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 8rem;
    margin-left: 2.5rem;
  }
}

.character-card-menu {
  position: absolute;
  top: 0;
  left: 0;
  background: url('../../../public/images/character_card.png') no-repeat top 100%;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 0.6rem;
  cursor: default;

  h2 {
    position: absolute;
    top: 1.8rem;
    font-size: var(--font-size-2);
    color: black;
  }

  &__close-btn {
    position: absolute;
    top: 1rem;
    left: 2.5rem;
    border: none;
    background: none;
    font-size: var(--font-size-3);
    color: white;
    cursor: pointer;
    box-shadow: none;
    filter: drop-shadow(0 0 0.1rem white);

    img {
      width: 100%;
      max-width: 1.4rem;
    }

    &:hover {
      transform: none;
      filter: drop-shadow(0 0 2rem var(--light-blue-0));
    }
  }

  &__link, &__delete-btn {
    text-decoration: none;
    font-size: var(--font-size-4);
    color: black;
    background-color: transparent;
    padding: 0.5rem;
    max-width: 7rem;
    max-height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.3rem;
    box-shadow: none;
    
    &:hover {
      color: var(--blue-2);
      transform: none;
    }
  }

  &__delete-btn {
    bottom: 2rem;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    
    &:hover {
      color: var(--dark-red-0);
    }
  }

  &__confirm-delete-btn {
    margin-top: 3rem;
    background-color: transparent;
    box-shadow: none;
    transition: all 0.2s ease-in-out;
    font-size: var(--font-size-4);
    
    &:hover {
      color: var(--dark-red-0);
      transform: none;
    }
  }

  &__cancel-btn {
    padding: 0.3rem;
    margin-top: -5.3rem;
    background-color: transparent;
    box-shadow: none;
    transition: all 0.2s ease-in-out;
    font-size: var(--font-size-4);
    
    &:hover {
      color: var(--blue-2);
      transform: none;
    }
  }
}
