.resources-page {
  background: url('../../../public/images/resources_page_bg.png') no-repeat;
  background-size: cover;
  width: 100%;
  min-height: 47.75rem;

  &__top-bar {
    background: url('../../../public/images/resources_page_top_bar.png') no-repeat;
    background-position: -0.3rem;
    height: 13.1rem;
    max-width: 1910px;
    text-align: center;

    &--container {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 80%;
      position: relative;
    }

    &--title {
      margin: 4.3rem 0 0;
      font-size: var(--font-size-7);
    }

    &--cart {
      font-size: var(--font-size-4);
      position: absolute;
      right: -26rem;
      bottom: 1.8rem;
      width: 100%;
      color: black;
      text-decoration: none;
      cursor: pointer;

      img {
        max-width: 1.4rem;
        margin-right: 0.3rem;
      }

      @media (width <= 965px) {
        right: -16rem;
      }

      @media (width <= 680px) {
        right: 0;
        bottom: -0.5rem;
      }
    }
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  &__left-sidebar {
    margin: 1rem 1.5rem 0 5rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    h4 {
      font-size: var(--font-size-2);
      margin-bottom: 0.3rem;
    }

    button {
      background-color: transparent;
      box-shadow: none;
      font-size: var(--font-size-0);
    }

    &--purchases {
      font-size: var(--font-size-4);
      margin: 1rem 0 0;
      text-align: center;
      color: inherit;
    }
  }

  &-shop {
    margin: 3.5rem auto;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    @media (width <= 879px) {
      &__product {
        text-align: center;

        &--box {
          justify-content: center;
        }
      }
    }

    &__product {
      display: flex;
      max-width: 55rem;
      justify-content: flex-start;

      &--image {
        box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
        max-width: 23rem;
        width: 100%;
      }

      &--right-container {
        margin-left: 2rem;
        max-width: 30rem;
      }

      &--name {
        font-size: var(--font-size-6);
        margin-bottom: 0.8rem;
        color: var(--blue-5);
      }

      &--subtitle {
        font-size: var(--font-size-5);
        margin-bottom: 0.8rem;
        color: #282924;
      }

      &--box {
        display: flex;
        flex-direction: column;
        gap: 1.3rem;

        &--qty {
          display: flex;
          gap: 1.5rem;
          width: fit-content;
        }
      }

      &--pre-order {
        max-width: 14rem;
        box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
        margin-left: 1.3rem;
      }

      &--price {
        width: 100%;
        margin: 0.5rem 0 0 1.3rem;
        font-size: var(--font-size-4);
      }

      &--qty {
        display: flex;
        font-weight: 900;
        gap: 0.3rem;
        align-items: center;
        margin-top: 0.3rem;
        
        input {
          max-width: 2.5rem;
          height: fit-content;
        }
      }

      &--add-to-cart-btn {
        background-color: transparent;
        border: none;
        box-shadow: none;
        max-width: 16rem;
        filter: drop-shadow(0 0 0.17rem black);
        
        img {
          box-shadow: none;
          max-width: 16rem;
        }

        &:hover {
          transform: scale(1.03, 1.03);
        }
      }

      &--added-to-cart-text {
        width: 100%;
        margin-left: 1.3rem;
        font-size: var(--font-size-3);
        color: rgb(30, 146, 30);
      }
    }
  }
}
