.character-sheet__blessing {
  border: 2px solid var(--dark-gray-0);
  border-left-width: 1px;
  border-right-width: 1px;
  width: 100%;

  &-header {
    border-top: 1px solid var(--dark-gray-0);
    border-bottom: 1px solid var(--dark-gray-0);
    grid-area: 1 / 1 / 1 / 12;
    text-align: center;
    background-color: var(--light-gray-2);
    max-height: 2rem;
    padding: 0.3rem;
    
    p {
      font-size: 1.13rem;
    }
  }

  &--desc {
    height: 4rem;
    border-bottom: 1px solid var(--dark-gray-0);
    padding: 0.5rem;
  }
}
