.purchases-page-container {
  background: url('../../../public/images/resources_page_bg.png') no-repeat;
  background-size: cover;
  width: 100%;
  min-height: 53.6rem;
  padding-top: 2rem;
}

.purchases-page {
  background-color: var(--light-gray-1);
  padding: 1rem;
  max-width: 30rem;
  margin: auto;
  margin-top: 5rem;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  h1 {
    margin-top: 0;
    font-size: var(--font-size-6);
    text-align: center;
  }

  &__product {
    display: flex;

    &--row {
      display: flex;
      gap: 0.6rem;
      margin-top: 0.3rem;
      flex-wrap: wrap;
    }

    img {
      max-width: 3rem;
      margin-right: 1rem;
      border-radius: 0.3rem;
    }

    h2 {
      margin: 0;
    }
  }

  &__back-btn {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }
}
