.character-sheet {
  margin: 1.3rem auto;
  background: url('../../../../public/images/character_sheet_bg_min.png');
  background-size: cover;
  border-radius: 0.3rem;
  padding: 3rem 3rem 20rem;
  max-width: 80rem;
  display: grid;
  grid-template-columns: repeat(11, 12fr);
  grid-gap: 1.5rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
  width: 100%;
  height: 113.1rem;
  overflow: hidden;

  table {
    border: 1px solid var(--dark-gray-0);
    border-collapse: collapse;

    td {
      border: 1px solid var(--dark-gray-0);
      padding: 0.3rem;
    }
  }

  &__top-btn-container {
    display: flex;
    gap: 0.5rem;
    margin: 1.1rem auto;
    max-width: 80rem;

    button {
      padding: 0.7rem;
      font-size: var(--font-size-3);
    }

    &--save-btn:hover {
      background-color: var(--green-0);
      color: white;
    }

    &--export-btn:hover {
      background-color: var(--blue-2);
      color: white;
    }

    &--level-up-btn:hover {
      background-color: purple;
      color: white;
    }
  }

  &__top-block { grid-area: 2 / 1 / 2 / 10 }

  &__health { grid-area: 2 / 10 / 2 / 19 }

  &__left-col {
    display: flex;
    flex-wrap: wrap;
    gap: 1.4rem;
    grid-area: 3 / 1 / 3 / 7;
    align-content: flex-start;

    .character-sheet__skills {
      max-width: 18rem;
    }

    .character-sheet__ability-scores {
      max-width: 16rem;
    }

    .character-sheet__treasure {
      max-width: 8rem;
    }

    .character-sheet__perks {
      max-height: 17rem;
    }

    .character-sheet__animals {
      max-width: 14rem;
      max-height: 20rem;
    }

    .character-sheet__divine-relics {
      max-width: 31.4em;
    }
  }

  &__right-col {
    display: flex;
    flex-wrap: wrap;
    gap: 1.4rem;
    grid-area: 3 / 7 / 3 / 19;
    align-content: flex-start;
  }

  &__sub-col {
    display: flex;
    gap: 1rem;
    width: 100%;
    // flex-wrap: wrap;
  }

  &--pdf {
    min-width: 80rem;
    box-shadow: none;
    border-radius: 0;

    button {
      display: none;
    }

    .character-sheet__character-details input {
      border: none;
    }
  }

  &__logo {
    max-width: 20rem;
    width: 100%;
    margin: 3rem auto;
  }

  input, &__field {
    background: transparent;
    border: none;
    border-bottom: 1px solid var(--dark-gray-0);
    padding: 0.5rem;
    max-height: 2rem;
  }

  input[type="checkbox"] {
    opacity: 0;
    cursor: pointer;
    position: absolute;
  }

  .checkbox {
    border: 1px solid black;
    position: absolute;
    width: 1rem;
    border-radius: 50%;
    height: 100%;
    max-height: 1rem;

    &--checked {
      background-color: black;
    }
  }
}

.character-sheet-2 {
  padding-bottom: 4rem;
}

.character-sheet-2--left-col {
  display: flex;
  flex-wrap: wrap;
  gap: 1.4rem;
  align-content: flex-start;
  grid-area: 2 / 1 / 12 / 6;
  margin-top: 2rem;
}

.character-sheet-2--right-col {
  display: flex;
  flex-wrap: wrap;
  gap: 1.4rem;
  grid-area: 2 / 6 / 12 / 12;
  align-content: flex-start;
  margin-top: 2rem;
}

.saved-anim {
  position: fixed;
  top: 6rem;
  right: 0;
  color: white;
  opacity: 0%;
  background-color: var(--dark-0);
  padding: 1rem;

  p {
    font-size: var(--font-size-3);
  }

  &--active {
    animation: save-anim 1.8s both ease-in-out;
  }
}

.desc-box {
  position: absolute;
  top: 0;
  background-color: var(--dark-0);
  color: white;
  padding: 1rem;
  border-radius: 0.3rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;

  p {
    font-size: var(--font-size-1);
  }
}

@keyframes save-anim {
  0% {
    opacity: 15%;
  }

  50% {
    opacity: 100%;
  }

  100% {
    opacity: 0%;
  }
}
