.admin-page {
  &__weapons {
    background-color: var(--bg-2);
    padding: 1rem;
    border-radius: 0.3rem;
    width: 100%;
    max-width: 70rem;
    box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;

    &--properties-inputs {
      display: flex;
      flex-wrap: wrap;
      gap: 0.8rem;

      button {
        align-self: flex-end;
      }
    }

    &--damage-inputs {
      display: flex;
      flex-wrap: wrap;
      gap: 0.8rem;

      button {
        align-self: flex-end;
      }
    }

    &--damage-types {
      padding-bottom: 1.5rem;
      border-bottom: 2px solid black;

      select, button {
        max-width: 10rem;
      }
    }
      
    p {
      font-size: var(--font-size-1);
    }
  }
  
  &__weapons-box {
    &--title {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }
  
  &__weapons-form {
    &--top-buttons {
      display: flex;
      gap: 0.3rem;
      margin-bottom: 1.5rem;
    }
  
    &--inputs {
      display: flex;
      gap: 1rem;
      flex-direction: column;
        
      input, textarea {
        font-size: var(--font-size-0);
      }

      input[type="checkbox"] {
        width: 0.8rem;
      }
  
      textarea {
        height: 5rem;
        resize: vertical;
      }
    }
  }
}
  