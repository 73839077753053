.level-up-modal {
  h2 {
    font-family: 'Castoro Titling', cursive;
    font-size: var(--font-size-4);
  }

  h3 {
    font-family: 'Castoro Titling', cursive;
    font-size: var(--font-size-3);
    text-decoration: underline;
    margin: 1rem 0;
  }

  select {
    background: transparent;
    padding: 0.3rem;
    border-radius: 0.3rem;
    box-shadow: rgba(0, 0, 0, 0.16) 0 1px 4px;
    font-size: var(--font-size-0);
  }

  p {
    font-size: var(--font-size-1);
  }

  button {
    cursor: pointer;
    transition: all 0.15s ease-in-out;
    padding: 0.5rem;
    border-radius: 0.3rem;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.16) 0 1px 4px;
    margin: 0.5rem;
    font-size: var(--font-size-1);

    &:hover {
      transform: scale(1.06, 1.06);
      box-shadow: rgba(87, 119, 171, 0.758) 0 1px 4px;
    }
  }

  &__modal-back-btn {
    position: absolute;
    left: 0;
    top: 0;
    background-color: transparent;
    box-shadow: none !important;
  }

  &__back-btn {
    margin-top: 1rem;
  };

  &__dropdown {
    padding: 1rem;
    border: 0.1rem solid #272727;
    border-radius: 0.3rem;
  }

  &__starting-gear {
    display: flex;
    flex-direction: column;
    max-width: 6rem;
    justify-content: center;
    align-items: center;
    margin: auto;
    gap: 0.5rem;

    &--title {
      margin-top: 0;
    }

    &--bottom-buttons {
      display: flex;
    }
  }
}

.culture-triats-modal {
  label {
    display: flex;
    flex-direction: column;
    margin: 1rem;
    gap: 0.3rem;
  }
}

.perk-or-skill-modal {
  background: url('../../../../public/images/boxes/feature_box.png') no-repeat center;
  background-size: contain;
  padding: 5rem;
  filter: drop-shadow(0 0 0.5rem black);

  select {
    margin-bottom: 0.4rem;
  }
}

.features-modal {
  margin: 0.5rem;

  &__features-list {
    display: flex;
    justify-content: center;
    flex-direction: column;

    .features-item {
      border: 1px solid black;
      border-radius: 0.2rem;
      box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
      transition: all 0.3s ease-in-out;

      &:hover, &--selected {
        transform: scale(1.02, 1.02);
        box-shadow: rgba(87, 119, 171, 0.758) 0 3px 8px;
      }

      h4 {
        font-size: var(--font-size-2);
        margin: 1rem 0;
      }
    }
  }
}

.ability-scores-modal {
  form {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-height: 18rem;

    button {
      align-self: center;
    }
  }

  &__ability-scores-list {
    font-size: var(--font-size-4);
    margin: 1rem;

    &--selected {
      text-decoration: line-through;
      color: red;
    }
  }

  &__score-input {
    select {
      margin-left: 0.3rem;
    }

    &--delete-btn {
      font-size: 1rem !important;
    }
  }
}

.weapon-modifier-modal, .blessings-modal {
  background: url('../../../../public/images/boxes/feature_box.png') no-repeat center;
  background-size: contain;
  padding: 8rem;
  filter: drop-shadow(0 0 0.5rem black);
}
