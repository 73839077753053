.navbar {
  position: relative;
  background: url('../../../public/images/navbar_bg.png');
  display: flex;
  align-items: center;
  gap: 1rem;
  height: 5.9rem;
  box-shadow: rgba(0, 0, 0, 0.15) 4px 4px 6.6px;

  &__link {
    text-decoration: none;
    color: white;
    font-size: var(--font-size-3);
    transition: all 0.3s ease-in-out;

    &--home {
      max-height: 4.5rem;
      position: absolute;
      left: 10rem;
      filter: drop-shadow(0 0 0.2rem black);

      img {
        cursor: pointer;
        max-width: 5rem;
        margin: -0.5rem 0.8rem;
      }
    }

    &--admin {
      color: var(--blue-2);
      position: absolute;
      left: 3.5rem;
      top: 1.5rem;

      &:hover {
        color: var(--blue-3) !important;
      }
    }

    &:hover {
      text-decoration: underline;
      color: var(--blue-4);
    }
  }

  &__hamburger-btn {
    display: none;
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    background-color: transparent;
    box-shadow: none;
    
    img {
      width: 1.3rem;
    }
  }

  &__small-display {
    display: none;
    background-color: var(--bg-0);
    padding: 1rem;
    margin-top: 7rem;
    z-index: 1;
    width: 100%;
    position: relative;

    &--link {
      text-decoration: none;
      color: var(--blue-4);
      margin: auto;
      margin-left: 0;

      &:hover {
        text-decoration: underline;
      }
    }

    &--small-display-home {
      max-width: 4.5rem;
      filter: drop-shadow(0 0 0.2rem black);
      margin-bottom: 1rem;
      padding-top: 0.5rem;

      img {
        cursor: pointer;
        max-width: 5rem;
        margin: -0.5rem 0.8rem;
      }
    }

    &--close-btn {
      box-shadow: none;
      max-width: 1.5rem;
      background-color: transparent;
      font-size: var(--font-size-3);
      position: absolute;
      right: 0.6rem;
      top: 0.4rem;
    }
  }

  &__small-display--open {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  @media (width <= 800px) {
    &__hamburger-btn {
      display: block;
    }
  }
}

.account-box {
  z-index: 1;
  position: absolute;
  top: 0;
  right: 10rem;
  filter: drop-shadow(0 0 0.1rem black);

  @media (width <= 800px) {
    display: none;
  }

  &__link {
    position: absolute;
    text-decoration: none;
    color: var(--blue-4);
    transition: all 0.15s ease-in-out;
    font-size: var(--font-size-2);

    &--account {
      top: 1.93rem;
      right: 5.8rem;
    }

    &--resources {
      top: 3.76rem;
      right: 5.25rem;
    }

    &--characters {
      top: 5.55rem;
      right: 4.85rem;
    }

    &--vtt {
      top: 7.5rem;
      right: 5rem;
    }
  
    &:hover {
      text-decoration: underline;
      color: var(--blue-3);
    }
  }
}