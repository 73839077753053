.character-sheet__gear {
  display: flex;
  flex-wrap: wrap;
  border: 2px solid var(--dark-gray-0);
  border-left-width: 1px;
  height: 100%;
  max-height: 38.3rem;
  grid-column-start: 1;
  grid-column-end: 12;
  position: relative;
  margin-top: 7rem;
  border-right: none;
  min-width: 73.9rem;

  &--top-bar {
    position: absolute;
    top: -1.8rem;
    display: flex;

    p {
      font-style: italic;
      min-width: 17rem;
      color: var(--dark-gray-0);
    }
  }

  &--top-bar-currency {
    width: 100%;
    margin-left: 15rem;
    display: flex;
    gap: 1rem;
    margin-top: -0.2rem;
    align-content: center;

    label {
      display: flex;
      align-items: center;
      font-size: var(--font-size-1);
    }

    input {
      border: none;
      max-width: 5rem;
      margin-left: 0.5rem;
    }
  }
}

.character-sheet__gear-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 37rem;
  max-width: 25rem;
  width: 100%;
  
  &--headers {
    display: flex;
    min-width: 73.9rem;
  }

  &-header {
    border-top: 1px solid var(--dark-gray-0);
    background-color: var(--light-gray-2);
    text-align: center;
    display: flex;
    align-items: center;
    width: 100%;
  
    p {
      font-size: 1.13rem;
      text-align: center;
    }

    &--qty-title {
      border-left: 1px solid var(--dark-gray-0);
      max-width: 3rem;
    }

    &--carried-title {
      border-left: 1px solid var(--dark-gray-0);
      border-right: 1px solid var(--dark-gray-0);
      max-width: 3rem;
    }

    &--lbs-title {
      border-right: 1px solid var(--dark-gray-0);
      max-width: 4rem;
    }
  }

  &--carried-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  .gear-circle {
    border: 1px solid black;
    width: 1rem;
    border-radius: 50%;
    height: 1rem;
  
    &--filled {
      background-color: black;
    }
  }

  &--delete-btn {
    position: absolute;
    right: 0;
    top: 0.15rem;
    max-width: 1.6rem;
    width: 100%;
    background-color: transparent;
    border: none;
    box-shadow: none;
    cursor: pointer;
  }

  &--total-lbs {
    position: absolute;
    bottom: -2.1rem;
    right: 0.05rem;
    border: 2px solid var(--dark-gray-0);
    border-left-width: 1px;
    border-right-width: 1px;
    border-top-width: 0;
    display: flex;
    align-items: center;
    color: var(--dark-gray-0);

    &-border {
      height: 2rem;
      border-right: 1px solid var(--dark-gray-0);
    }

    &-title {
      margin: 0 4rem 0 3.6rem;
    }

    &-amount {
      margin: 0 1.5rem 0 2rem;

      &--exceeded {
        color: red;
      }
    }
    
    p {
      height: 100%;
    }
  }

  &--max-carry-weight {
    position: absolute;
    bottom: -2.1rem;
    left: 0;
    border-bottom-width: 0;
    display: flex;
    align-items: center;
    color: var(--dark-gray-0);
    
    p {
      height: 100%;
    }
  }

  &--row {
    display: flex;
    max-width: 24.63rem;
    width: 100%;
  }

  &--name {
    width: 100%;

    &-empty {
      padding: 0.97rem;
    }
  }

  &--qty {
    max-width: 3rem;
    width: 100%;
    border-left: 1px solid var(--dark-gray-0);
    text-align: center;
  }

  &--carried {
    max-width: 3rem;
    width: 100%;
    border-left: 1px solid var(--dark-gray-0);
    border-right: 1px solid var(--dark-gray-0);
  }

  &--lbs {
    position: relative;
    max-width: 4rem;
    width: 100%;
    border-right: 1px solid var(--dark-gray-0);
  }
}
