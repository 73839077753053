.admin-page {
  &__armor {
    background-color: var(--bg-2);
    padding: 1rem;
    border-radius: 0.3rem;
    width: 100%;
    max-width: 70rem;
    box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
      
    p {
      font-size: var(--font-size-1);
    }

    &--top-buttons {
      display: flex;
      gap: 0.3rem;
      margin-bottom: 1rem;
    }
  }

  &__armor-categories {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    
    button {
      font-size: var(--font-size-2);
      padding: 0.5rem;

      &:hover {
        transform: scale(1.02, 1.02);
      }
    }
  }
  
  &__armor-box {
    margin-bottom: 1rem;

    &--title {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      max-height: 3rem;
    }
  }
  
  &__armor-form {
    &--top-buttons {
      display: flex;
      gap: 0.3rem;
      margin-bottom: 1.5rem;
    }
  
    &--inputs {
      display: flex;
      gap: 1rem;
      flex-direction: column;
        
      input, textarea {
        font-size: var(--font-size-0);
      }
  
      textarea {
        height: 5rem;
        resize: vertical;
      }
    }
  }
}
