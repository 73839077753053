.character-sheet__companion {
  display: grid;
  grid-template-columns: repeat(11, 1fr);
  grid-template-rows: repeat(12, 2rem) 0;
  border: 2px solid var(--dark-gray-0);
  border-left-width: 1px;
  border-right-width: 1px;
  width: 100%;

  &-header {
    grid-area: 1 / 1 / 1 / 12;

    p {
      font-size: 1.13rem;
      text-align: center;
      border-top: 1px solid var(--dark-gray-0);
      border-bottom: 1px solid var(--dark-gray-0);
      background-color: var(--light-gray-2);
      padding: 0.35rem;
    }
  }

  &--name {
    grid-area: 2 / 1 / 2 / 4;
    border-right: 1px solid var(--dark-gray-0) !important;
  }

  &--creature-type {
    grid-area: 2 / 4 / 2 / 8;
    border-right: 1px solid var(--dark-gray-0) !important;
  }

  &--size {
    grid-area: 2 / 8 / 2 / 12;
  }

  &--exhaustion {
    grid-area: 3 / 1 / 3 / 4;
    border-right: 1px solid var(--dark-gray-0) !important;
  }

  &--speed {
    grid-area: 3 / 4 / 3 / 7;
    border-right: 1px solid var(--dark-gray-0) !important;
  }

  &--fight-or-flight {
    grid-area: 3 / 7 / 3 / 12;

    span {
      color: var(--dark-gold-2);
    }
  }

  &--resistances {
    grid-area: 4 / 1 / 6 / 4;
    border-right: 1px solid var(--dark-gray-0) !important;
    max-height: 10rem !important;
    height: 4rem;
    resize: none;
    overflow: hidden;
  }

  &--vulnerabilities {
    grid-area: 4 / 4 / 6 / 8;
    border-right: 1px solid var(--dark-gray-0) !important;
    max-height: 10rem !important;
    height: 4rem;
    resize: none;
    overflow: hidden;
  }

  &--attack-dice {
    grid-area: 4 / 8 / 4 / 12;
  }

  &--defense-bonus {
    grid-area: 5 / 8 / 5 / 12;
  }

  &--attacks {
    grid-area: 6 / 1 / 9 / 12;
    max-height: 10rem !important;
    resize: none;
    overflow: hidden;
  }

  &--special-abilities {
    grid-area: 9 / 1 / 12 / 12;
    max-height: 10rem !important;
    resize: none;
    overflow: hidden;
  }

  &--physical {
    grid-area: 12 / 1 / 12 / 3;
    display: flex;

    input {
      border: none;
      margin-top: 0.07rem;
      max-width: 3rem;
    }
  }

  &--education {
    grid-area: 12 / 3 / 12 / 5;
    display: flex;

    input {
      border: none;
      margin-top: 0.07rem;
      max-width: 3rem;
    }
  }

  &--technical {
    grid-area: 12 / 5 / 12 / 7;
    display: flex;

    input {
      border: none;
      margin-top: 0.07rem;
      max-width: 3rem;
    }
  }

  &--social {
    grid-area: 12 / 7 / 12 / 9;
    display: flex;

    input {
      border: none;
      margin-top: 0.07rem;
      max-width: 3rem;
    }
  }

  &--awareness {
    grid-area: 12 / 9 / 12 / 12;
    display: flex;

    input {
      border: none;
      margin-top: 0.07rem;
      max-width: 3rem;
    }
  }

  &--courage {
    grid-area: 12 / 11 / 12 / 12;
    display: flex;

    input {
      border: none;
      margin-top: 0.07rem;
      max-width: 3rem;
    }
  }
}
