.handbook-page {
  display: flex;
  justify-content: center;
  padding-top: 6rem;
  background: url('../../../public/images/resources_page_bg.png') no-repeat;
  background-size: cover;
}

iframe, object {
  width: 100%;
  height: 55rem;
  max-width: 1200px;
  padding: 0 2rem;
}
