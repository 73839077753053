.login-page, .register-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8rem;
  background: url('../../../public/images/login_page_bg.png') no-repeat;
  background-size: cover;
  height: 100vh;

  h1 {
    font-size: var(--font-size-6);
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.6rem;
    background: url('../../../public/images/character_card.png') no-repeat center;
    background-size: contain;
    padding: 8rem;
    filter: drop-shadow(0 0 0.3rem black);

    label {
      display: flex;
      flex-direction: column;
      font-size: var(--font-size-4);
      gap: 0.1rem;
    }

    input {
      border: none;
      padding: 0.5rem;
      border-radius: 0.3rem;
      box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
    }

    button {
      margin-top: 0.5rem;
      padding: 0.3rem;
      font-size: var(--font-size-0);
    }
  }

  &__link {
    font-size: var(--font-size-1);
  }
}
