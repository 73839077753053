.admin-page {
  &__features {
    background-color: var(--bg-2);
    padding: 1rem;
    border-radius: 0.3rem;
    width: 100%;
    max-width: 70rem;
    box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
    
    p {
      font-size: var(--font-size-1);
    }
  }

  &__features-box {
    &--title {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }

  &__features-form {
    &--top-buttons {
      display: flex;
      gap: 0.3rem;
      margin-bottom: 1.5rem;
    }

    &--inputs {
      display: flex;
      gap: 1rem;
      flex-direction: column;
      
      input, textarea {
        font-size: var(--font-size-0);
      }

      textarea {
        height: 5rem;
        resize: vertical;
      }
    }

    &--feature-list {
      border-top: 3px solid var(--dark-gray-0);
      margin-top: 1rem;

      h3 {
        font-size: var(--font-size-4);
        margin: 1.5rem 0 1rem;
      }
    }
  }
}
