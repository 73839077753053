.character-sheet__features {
  display: grid;
  grid-template-columns: repeat(11, 12fr);
  grid-template-rows: repeat(21, 2rem) 0;
  border: 2px solid var(--dark-gray-0);
  border-left-width: 1px;
  border-right-width: 1px;

  &-header {      
    p {
      font-size: 1.13rem;
      text-align: center;
    }

    &--name-title {
      grid-area: 1 / 1 / 1 / 5;
      border-top: 1px solid var(--dark-gray-0);
      background-color: var(--light-gray-2);
    }

    &--range-title {
      grid-area: 1 / 5 / 1 / 6;
      border-top: 1px solid var(--dark-gray-0);
      border-left: 1px solid var(--dark-gray-0);
      border-right: 1px solid var(--dark-gray-0);
      background-color: var(--light-gray-2);
    }

    &--type-title {
      grid-area: 1 / 6 / 1 / 7;
      border-top: 1px solid var(--dark-gray-0);
      border-right: 1px solid var(--dark-gray-0);
      background-color: var(--light-gray-2);
    }

    &--exhaustion-title {
      grid-area: 1 / 7 / 1 / 12;
      border-top: 1px solid var(--dark-gray-0);
      background-color: var(--light-gray-2);
      border-right: 1px solid var(--dark-gray-0);
    }
  }

  &--delete-btn {
    position: absolute;
    right: -1.5rem;
    top: 0.4rem;
    max-width: 1.6rem;
    width: 100%;
    background-color: transparent;
    border: none;
    box-shadow: none;
    cursor: pointer;
  }

  &--level {
    padding: 0.1rem 0 0;
    text-align: center;

    p {
      color: var(--dark-gold-2);
      font-size: var(--font-size-3);
    }
  }


  // Feature rows 1
  &--level-2 {
    grid-area: 2 / 1 / 2 / 2;
    border-right: 1px solid var(--dark-gray-0);
  }
  &--name-2 { grid-area: 2 / 2 / 2 / 5 }

  &--range-2 {
    grid-area: 2 / 5 / 2 / 6;
    border-left: 1px solid var(--dark-gray-0);
    border-right: 1px solid var(--dark-gray-0);
  }

  &--type-2 {
    grid-area: 2 / 6 / 2 / 7;
    border-right: 1px solid var(--dark-gray-0);
  }

  &--exhaustion-2 {
    grid-area: 2 / 7 / 2 / 12;
    position: relative;
    border-right: 1px solid var(--dark-gray-0);
  }

  &--desc-2 {
    grid-area: 3 / 1 / 6 / 12;
    position: relative;
    border-right: 1px solid var(--dark-gray-0);
    max-height: 100%;
  }

  // Feature rows 2
  &--level-3 {
    grid-area: 6 / 1 / 6 / 2;
    border-right: 1px solid var(--dark-gray-0);
  }
  &--name-3 { grid-area: 6 / 2 / 6 / 5 }

  &--range-3 {
    grid-area: 6 / 5 / 6 / 6;
    border-left: 1px solid var(--dark-gray-0);
    border-right: 1px solid var(--dark-gray-0);
  }

  &--type-3 {
    grid-area: 6 / 6 / 6 / 7;
    border-right: 1px solid var(--dark-gray-0);
  }

  &--exhaustion-3 {
    grid-area: 6 / 7 / 6 / 12;
    position: relative;
    border-right: 1px solid var(--dark-gray-0);
  }

  &--desc-3 {
    grid-area: 7 / 1 / 10 / 12;
    position: relative;
    border-right: 1px solid var(--dark-gray-0);
    max-height: 100%;
  }

  // Feature rows 3
  &--level-4 {
    grid-area: 10 / 1 / 10 / 2;
    border-right: 1px solid var(--dark-gray-0);
  }
  &--name-4 { grid-area: 10 / 2 / 10 / 5 }

  &--range-4 {
    grid-area: 10 / 5 / 10 / 6;
    border-left: 1px solid var(--dark-gray-0);
    border-right: 1px solid var(--dark-gray-0);
  }

  &--type-4 {
    grid-area: 10 / 6 / 10 / 7;
    border-right: 1px solid var(--dark-gray-0);
  }

  &--exhaustion-4 {
    grid-area: 10 / 7 / 10 / 12;
    position: relative;
    border-right: 1px solid var(--dark-gray-0);
  }

  &--desc-4 {
    grid-area: 11 / 1 / 14 / 12;
    position: relative;
    border-right: 1px solid var(--dark-gray-0);
    max-height: 100%;
  }

  // Feature rows 4
  &--level-5 {
    grid-area: 14 / 1 / 14 / 2;
    border-right: 1px solid var(--dark-gray-0);
  }
  &--name-5 { grid-area: 14 / 2 / 14 / 5 }

  &--range-5 {
    grid-area: 14 / 5 / 14 / 6;
    border-left: 1px solid var(--dark-gray-0);
    border-right: 1px solid var(--dark-gray-0);
  }

  &--type-5 {
    grid-area: 14 / 6 / 14 / 7;
    border-right: 1px solid var(--dark-gray-0);
  }

  &--exhaustion-5 {
    grid-area: 14 / 7 / 14 / 12;
    position: relative;
    border-right: 1px solid var(--dark-gray-0);
  }

  &--desc-5 {
    grid-area: 15 / 1 / 18 / 12;
    position: relative;
    border-right: 1px solid var(--dark-gray-0);
    max-height: 100%;
  }

  // Feature rows 5
  &--level-6 {
    grid-area: 18 / 1 / 18 / 2;
    border-right: 1px solid var(--dark-gray-0);
  }
  &--name-6 { grid-area: 18 / 2 / 18 / 5 }

  &--range-6 {
    grid-area: 18 / 5 / 18 / 6;
    border-left: 1px solid var(--dark-gray-0);
    border-right: 1px solid var(--dark-gray-0);
  }

  &--type-6 {
    grid-area: 18 / 6 / 18 / 7;
    border-right: 1px solid var(--dark-gray-0);
  }

  &--exhaustion-6 {
    grid-area: 18 / 7 / 18 / 12;
    position: relative;
    border-right: 1px solid var(--dark-gray-0);
  }

  &--desc-6 {
    grid-area: 19 / 1 / 22 / 12;
    position: relative;
    border-right: 1px solid var(--dark-gray-0);
    max-height: 100%;
  }
}
  
.character-sheet {
  &__daily-slots-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    margin-bottom: -0.8rem;
    border: 2px solid var(--dark-gray-0);
    
    p {
      padding: 0.4rem;
      background-color: var(--light-gray-2);
      border-right: 1px solid var(--dark-gray-0);
    }
  }

  &__daily-slots-circles {
    display: flex;
    align-items: center;
    padding-left: 0.3rem;
    flex-wrap: wrap;
    gap: 0.5rem;
  }
    
  .daily-slot-circle {
    border: 1px solid var(--dark-gold-2);
    width: 1rem;
    border-radius: 50%;
    height: 1rem;
    margin: 0 0.3rem;
    
    &--filled {
      border-width: 3px;
    }
  }
}
