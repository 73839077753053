.character-sheet__divine-relics {
    display: grid;
    grid-template-columns: repeat(11, 12fr);
    grid-template-rows: repeat(8, 1fr);
    border: 2px solid var(--dark-gray-0);
    border-left-width: 1px;
    border-right-width: 1px;
    width: 100%;
  
    &-header {
      grid-area: 1 / 1 / 1 / 12;
      border-top: 1px solid var(--dark-gray-0);
      background-color: var(--light-gray-2);
      
      p {
        font-size: 1.13rem;
        text-align: center;
      }
    }

    &--delete-btn {
      position: absolute;
      right: 0.15rem;
      top: 0rem;
      bottom: 0.1rem;
      max-width: 1.85rem;
      width: 100%;
      border: none;
      box-shadow: none;
      cursor: pointer;
    }

    form button {
      position: absolute;
      right: 0.3rem;
      top: 0.1rem;
      bottom: 0.1rem;
      max-width: 2.5rem;
      width: 100%;
      border: none;
      border-radius: 0.3rem;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
      background-color: #ffffff82;
      cursor: pointer;
    }

    input {
      border: none;
      padding: 0;
      width: 81%;
      height: 100%;
    }
  
    @for $i from 1 through 8 {
      &--name-#{$i} {
        grid-area: #{$i} / 1 / #{$i} / 12;
        position: relative;
      }
    }
  }
  