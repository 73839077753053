.admin-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8rem;

  h1 {
    color: var(--dark-0);
    font-size: var(--font-size-7);
  }

  h2 {
    font-size: var(--font-size-6);
  }

  h3 {
    font-size: var(--font-size-3);
  }

  &__btn-container {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    padding: 1rem;
    border-radius: 0.3rem;
    justify-content: center;
    margin-bottom: 1rem;

    button {
      padding: 0.5rem;
      border-radius: 0.3rem;
      font-size: var(--font-size-2);
    }
  }

  label {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    font-size: var(--font-size-3);
  }
}
