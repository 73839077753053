.admin-page {
  &__culture-traits {
    background-color: var(--bg-2);
    padding: 1rem;
    border-radius: 0.3rem;
    width: 100%;
    max-width: 70rem;
    box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
      
    p {
      font-size: var(--font-size-1);
    }
  }
  
  &__culture-traits-box {
    &--title {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }

    &--container {
      display: flex;
      flex-direction: column;

      &--text {
        color: var(--green-0);
        font-size: var(--font-size-2);
        margin: 1.5rem 0 -1rem;

        &--red {
          font-size: var(--font-size-2);
          margin: 1.5rem 0 -1rem;
          color: var(--dark-red-0);
        }
      }
    }
  }
  
  &__culture-traits-form {
    &--top-buttons {
      display: flex;
      gap: 0.3rem;
      margin-bottom: 1.5rem;
    }
  
    &--inputs {
      display: flex;
      gap: 1rem;
      flex-direction: column;
        
      input, textarea {
        font-size: var(--font-size-0);
      }
  
      textarea {
        height: 5rem;
        resize: vertical;
      }
    }
  }

  &__culture-traits--culture-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: flex-start;

    button {
      max-width: 12rem;
      font-size: var(--font-size-3);
    }
  }
}
  