.character-sheet__ability-scores {
  border: 2px solid var(--dark-gray-0);
  border-left-width: 1px;
  border-right-width: 1px;
  max-height: 8.5rem;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(11, 1fr);
  grid-template-rows: repeat(4, 2rem) 0;
  border-bottom: 3px solid var(--dark-gray-0);

  &--title {
    grid-area: 1 / 1 / 1 / 12;
    text-align: center;
    background-color: var(--light-gray-2);
    width: 100%;
    border-bottom: 1px solid var(--dark-gray-0) !important;
    border-top: 1px solid var(--dark-gray-0) !important;
  }

  .character-sheet__field {
    border: none;
  }

  input {
    max-width: 1.5rem;
    margin-left: 1rem;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type="number"] {
      -moz-appearance: textfield;
    }
  }
  
  &--physical { grid-area: 2 / 1 / 2 / 5 }
  &--physical-value { grid-area: 2 / 5 / 2 / 6 }

  &--education { grid-area: 3 / 1 / 3 / 5 }
  &--education-value { grid-area: 3 / 5 / 3 / 6 }

  &--technical { grid-area: 4 / 1 / 4 / 5 }
  &--technical-value { grid-area: 4 / 5 / 4 / 6 }

  &--social { grid-area: 2 / 6 / 2 / 10 }
  &--social-value { grid-area: 2 / 10 / 2 / 12 }

  &--awareness { grid-area: 3 / 6 / 3 / 10 }
  &--awareness-value { grid-area: 3 / 10 / 3 / 12 }

  &--courage { grid-area: 4 / 6 / 4 / 10 }
  &--courage-value { grid-area: 4 / 10 / 4 / 12 }
}
